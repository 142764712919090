<template>
	<v-card flat class="">
		<v-data-table dense :headers="table.headers" :items="table.items" :options.sync="tableOptions"
			:server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Pengguna</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="table.search" @change="getDataFromApi()"
						:prepend-inner-icon="icons.mdiMagnify" label="Cari dengan username/nama/role/kelompok"
						single-line hide-details></v-text-field>

					<!-- dialog user -->
					<v-dialog v-model="dialog" max-width="800px">
						<!-- <template v-slot:activator="{ on, attrs }">
							<v-btn x-small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
								Tambah Item
							</v-btn>
						</template> -->
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12" sm="6" md="4">
										<v-text-field readonly v-model="form.editedItem.username" outlined dense
											hide-details="" label="username :"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field readonly v-model="form.editedItem.email" outlined dense
											hide-details="" label="email :"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4" v-if="form.editedItem.role == 'REGISTERED'">
										<v-text-field readonly v-model="form.editedItem.role_temp" outlined dense
											hide-details="" label="role temp :"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4" v-else>
										<v-text-field readonly v-model="form.editedItem.role" outlined dense
											hide-details="" label="role :"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field readonly v-model="form.editedItem.profesi" outlined dense
											hide-details="" label="Kelompok :"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										Verifikasi User:
										<v-chip color="warning" v-if="form.editedItem.verified_by == null">Not
											Verified</v-chip>
										<v-chip color="success" v-else>Verified at {{ form.editedItem.verified_at
											}}</v-chip>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										Status Aktif:
										<v-chip color="error" v-if="form.editedItem.flag_selesai == '1'">Non
											Aktif</v-chip>
										<v-chip color="success" v-else>Aktif</v-chip>
									</v-col>
								</v-row>
							</v-card-text>
							<div v-if="form.editedItem">
								<div
									v-if="form.editedItem.role == 'Peserta Didik' || form.editedItem.role == 'PESERTA DIDIK'">
									<register-peserta-dokter
										v-if="form.editedItem.profesi == 'Dokter'"></register-peserta-dokter>
									<register-peserta-pPDS
										v-if="form.editedItem.profesi == 'PPDS'"></register-peserta-pPDS>
									<register-peserta-keperawatanDll
										v-if="form.editedItem.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'"></register-peserta-keperawatanDll>
								</div>
								<div v-else-if="form.editedItem.role == 'PEMBIMBING KLINIK'">
									<register-pembimbing></register-pembimbing>
								</div>
							</div>
							<v-card-actions>
								<v-btn v-if="form.editedItem.verified_by == null" color="primary"
									@click="verifikasiUser()">
									Verifikasi User
								</v-btn>
								<v-btn color="error" v-if="form.editedItem.flag_selesai == '0'"
									@click="nonAktifkanUser()">
									Non Aktifkan
								</v-btn>
								<v-btn color="warning" @click="showDialogResetPassword()">
									Reset Password
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- dialog reset password -->
					<v-dialog v-model="dialogResetPassword" max-width="500px">
						<v-card>
							<v-card-title>
								<span class="text-h5">Form Reset Password</span>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="form.editedItem.password_baru" outlined dense
											hide-details="" label="Password Baru :" type="password"></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="saveResetPassword()">Simpan</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:item.nama="{ item }">
				{{ item.username }}/ {{ item.nama }}
			</template>
			<template v-slot:item.verifikasi="{ item }">
				<v-chip x-small color="warning" v-if="item.verified_by == null">NotVerified</v-chip>
				<v-chip x-small color="success" v-else>Verified</v-chip>/
				<v-chip x-small color="error" v-if="item.flag_selesai == '1'">Non Aktif</v-chip>
				<v-chip x-small color="success" v-else>Aktif</v-chip>
			</template>
			<template v-slot:item.actions="{ item }">
				<!-- <v-icon small class="mr-2" @click="editItem(item)">
					{{ icons.mdiPencil }}
				</v-icon> -->
				<!-- <router-link :to="{ name: 'AdminPraktikAnggota', params: { id: item.id } }"> -->
				<v-btn title="Lihat" x-small color="primary" @click="editItem(item)">
					<v-icon small>
						{{ icons.mdiEye }}
					</v-icon>
				</v-btn>
				<!-- </router-link> -->
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="getDataFromApi">
					Reset
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'
import RegisterPesertaDokter from '@/views/admin/RegisterPeserta/RegisterPesertaDokter.vue'
import RegisterPesertaPPDS from '@/views/admin/RegisterPeserta/RegisterPesertaPPDS.vue'
import RegisterPesertaKeperawatanDll from '@/views/admin/RegisterPeserta/RegisterPesertaKeperawatanDll.vue'
import RegisterPembimbing from '@/views/admin/RegisterPembimbing/RegisterPembimbing.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
	components: {
		UploadDokumenRegistrasi,
		RegisterPesertaDokter,
		RegisterPesertaPPDS,
		RegisterPesertaKeperawatanDll,
		RegisterPembimbing
	},
	data: () => ({
		icons: {
			mdiEye,
			mdiPencil,
			mdiMagnify
		},
		tableOptions: {},
		table: {
			loading: true,
			headers: [
				{
					text: 'No',
					align: 'start',
					sortable: false,
					value: 'no',
				},
				{ text: 'Email', value: 'email', sortable: false, },
				{ text: 'Username/ Nama', value: 'nama', sortable: false, },
				{ text: 'Role', value: 'role', sortable: false, },
				{ text: 'Kelompok', value: 'profesi', sortable: false, },
				{ text: 'Verifikasi/ Aktif', value: 'verifikasi', sortable: false, },
				{ text: 'Aksi', value: 'actions', sortable: false, },
			],
			items: [],
			totalItems: 0,
			search: ''
		},
		dialog: false,
		dialogResetPassword: false,
		form: {
			editedIndex: -1,
			editedItem: {
				profesi: '',
				stase_id: 0,
				tanggal_awal: 0,
				tanggal_akhir: 0,
				password_baru: '',
			},
			defaultItem: {
				profesi: '',
				stase_id: 0,
				tanggal_awal: 0,
				tanggal_akhir: 0,
				password_baru: '',
			},
			profesis: [
				'Dokter',
				'PPDS',
				'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain',
			],
		}
	}),
	watch: {
		tableOptions: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
		dataToBeWatched: function (val) {
			//do something when the data changes.
			if (val) {
				this.makeSmthWhenDataChanged();
			}
		}
	},
	computed: {
		formTitle() {
			return this.form.editedIndex === -1 ? 'Tambah Item' : 'Lihat Data'
		},
		userSelected() {
			return this.$store.getters.userSelected
		},
	},
	methods: {
		verifikasiUser() {
			let conf = confirm("Apakah yakin data sudah valid dan lengkap?")
			if (conf) {
				const fmData = new FormData()
				fmData.append('user_id', this.userSelected.id)
				axiosPostAuth('api/User/verifikasiUser', fmData)
					.then(response => {
						this.dialog = false
						this.getDataFromApi()
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		nonAktifkanUser() {
			let conf = confirm("Apakah yakin akan dinonaktifkan?")
			if (conf) {
				const fmData = new FormData()
				fmData.append('user_id', this.userSelected.id)
				axiosPostAuth('api/User/nonAktifkanUser', fmData)
					.then(response => {
						this.dialog = false
						this.getDataFromApi()
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		save() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('profesi', this.form.editedItem.profesi)
				if (this.form.editedItem.profesi !== 'PPDS') {
					fmData.append('stase_id', this.form.editedItem.stase_id)
				}
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/updatePraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialog = false
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('profesi', this.form.editedItem.profesi)
				if (this.form.editedItem.profesi !== 'PPDS') {
					fmData.append('stase_id', this.form.editedItem.stase_id)
				}
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/addPraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getDataFromApi() {
			const { page, itemsPerPage } = this.tableOptions
			const fmData = new FormData()
			fmData.append('page', page)
			fmData.append('itemsPerPage', itemsPerPage)
			fmData.append('search', this.table.search)
			axiosPostAuth('api/User/getUser_datatable', fmData)
				.then(response => {
					this.table.items = response.data
					this.table.totalItems = response.total
					this.table.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		editItem(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.editedItem = Object.assign({}, item)
			this.$store.commit('setUserSelected', item)
			this.dialog = true
		},
		showDialogResetPassword() {
			let conf = confirm("Apakah yakin akan mereset password?")
			if (conf) {
				this.form.editedItem.password_baru = ''
				this.dialogResetPassword = true
			}
		},
		saveResetPassword() {
			const fmData = new FormData()
			fmData.append('user_id', this.userSelected.id)
			fmData.append('password_baru', this.form.editedItem.password_baru)
			axiosPostAuth('api/User/resetPassword', fmData)
				.then(response => {
					if (response.code == 200) {
						alert("Password berhasil direset")
						this.form.editedItem.password_baru = ''
						this.dialogResetPassword = false
					} else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		}
	},
}
</script>