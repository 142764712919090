<template>
  <div>
    <!--UPLOAD-->
    <div enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <!-- <h3>Upload File</h3> -->
      <div class="dropbox">
        <input type="file" :name="uploadFieldName" :disabled="isSaving"
          @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
          accept="application/pdf" class="input-file">
        <p v-if="isInitial">
          Drag your file(s) here to begin<br> or click to browse
        </p>
        <p v-if="isSaving">
          Uploading {{ fileCount }} files...
        </p>
      </div>
    </div>
    <!--SUCCESS-->
    <div v-if="isSuccess">
      <p>
        <a :href="fileUploadedUrl" target="_blank">Lihat File</a>&nbsp;
        <!-- <a href="javascript:void(0)" @click="reset()">Upload ulang</a> -->
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h3>Gagal Upload.</h3>
      <p>
        <a href="javascript:void(0)" @click="reset()">Coba Lagi</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIROOT

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  props: {
    namaDokumen: { type: String, default: '' },
  },
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files',
      fileUploadedUrl: '',
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    userSelected() {
      return this.$store.getters.userSelected
    },
    headers() {
      return {
        Authorization: this.$store.state.token,
      }
    },
  },
  watch: {
    userSelected: function (newQuestion, oldQuestion) {
      this.getFiles()

    }
  },
  methods: {
    reset() {
      const conf = confirm('Apakah yakin akan upload ulang? upload ulang akan menghapus file sebelumya')
      if (conf) {
        this.currentStatus = STATUS_INITIAL
        this.uploadError = null

        const headers = this.headers

        const formData = new FormData()
        formData.append('user_id', this.userSelected.id)
        formData.append('nama_dokumen', this.namaDokumen)
        axios
          .post(`${apiRoot}/api/RegisterLanjutan/deleteDokumen`, formData, { headers })
          .then(response => {
            if (response.data.code === 200) {
              this.currentStatus = STATUS_INITIAL
            } else {
              this.currentStatus = STATUS_INITIAL
            }
          })
          .catch(error => {
            this.currentStatus = STATUS_INITIAL
          })
      }
    },
    save(formData) {
      // upload data to the server
      const headers = this.headers
      this.currentStatus = STATUS_SAVING
      axios
        .post(`${apiRoot}/api/RegisterLanjutan/addDokumen`, formData, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.fileUploadedUrl = response.data.file_url
            this.currentStatus = STATUS_SUCCESS
          } else {
            alert(response.data.message)
            this.currentStatus = STATUS_FAILED
          }
        })
        .catch(error => {
          this.currentStatus = STATUS_FAILED
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append('user_id', this.userSelected.id);
          formData.append('nama_dokumen', this.namaDokumen);
          formData.append(fieldName, fileList[x], fileList[x].name);
        })

      // save it
      this.save(formData)
    },
    getFiles() {
      this.currentStatus = STATUS_INITIAL
      const headers = this.headers
      const formData = new FormData()
      formData.append('user_id', this.userSelected.id)
      formData.append('nama_dokumen', this.namaDokumen)
      axios
        .post(`${apiRoot}/api/RegisterLanjutan/getDokumen`, formData, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.fileUploadedUrl = response.data.file_url
            this.currentStatus = STATUS_SUCCESS
          } else {
            this.currentStatus = STATUS_INITIAL
          }
        })
        .catch(error => {
          this.currentStatus = STATUS_FAILED
        })
    }
  },
  created() {
    this.getFiles()
  },
}
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  // padding: 10px 10px;
  min-height: 30px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>