<template>
    <div>
        <v-card flat class="mt-2">
            <v-form class="multi-col-validation">
                <v-card-title>Register Lanjutan PPDS</v-card-title>
                <v-card-text class="">
                    <v-row>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userSelected.username" label="Username :" dense outlined
                                readonly></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userSelected.email" label="Email :" dense outlined
                                readonly></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userSelected.nama" label="Nama :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userSelected.nim" label="NIM :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-autocomplete v-model="userSelected.id_institusi" :items="institusi_pendidikans" outlined
                                dense label="Institusi Pendidikan :" item-text="nama" item-value="id"></v-autocomplete>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-autocomplete v-model="userSelected.id_prodi" :items="prodis" outlined dense
                                label="Prodi :" item-text="nama" item-value="id"></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="6" md="3">
                            <v-select v-model="userSelected.jenis_kelamin" outlined dense label="Jenis Kelamin :"
                                :items="['Pria', 'Wanita']"></v-select>
                        </v-col> -->
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userSelected.level" label="Level :" dense outlined></v-text-field>
                        </v-col>
                    </v-row>
                    Upload Dokumen :
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        No
                                    </th>
                                    <th class="text-left">
                                        Nama
                                    </th>
                                    <th class="text-left">
                                        File
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in userTambahan.dokumen" v-bind:key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.nama }}</td>
                                    <td><uploaded-dokumen-registrasi
                                            :namaDokumen="item.id"></uploaded-dokumen-registrasi></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>

                <!-- <v-card-text>
                    <v-btn color="primary" class="me-3 mt-3" @click="simpan">
                        Simpan
                    </v-btn>
                </v-card-text> -->
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import UploadedDokumenRegistrasi from '@/components/upload/UploadedDokumenRegistrasi.vue'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        UploadedDokumenRegistrasi,
    },
    data: () => ({
        userTambahan: {
            // username: '',
            // email: '',
            // nama: '',
            // nim: '',
            // institusi_pendidikan: '',
            // prodi: '',
            // jenis_kelamin: '',
            // alamat: '',
            // level: '',
            dokumen: [
                {
                    id: 'surat_pengantar',
                    nama: 'Surat Pengantar/surat tugas dari institusi'
                },
                {
                    id: 'ijazah',
                    nama: 'Ijazah'
                },
                {
                    id: 'str',
                    nama: 'STR'
                },
                {
                    id: 'sip',
                    nama: 'SIP'
                },
                {
                    id: 'sertifikat_kompetensi',
                    nama: 'Sertifikat Kompetensi/ Identifikasi Level'
                },
                {
                    id: 'klasifikasi_akademik',
                    nama: 'Klasifikasi Akademik'
                },
                {
                    id: 'rekening_bank',
                    nama: 'Rekening Bank (Level Hijau) Diutamakan Mandiri/ Bank Jateng'
                },
                {
                    id: 'npwp',
                    nama: 'NPWP'
                },
                {
                    id: 'ktp',
                    nama: 'KTP'
                },
                {
                    id: 'pas_foto',
                    nama: 'Pas Foto (background Merah)'
                },
            ]
        },
        institusi_pendidikans: [],
        prodis: [],
    }),
    computed: {
        headers() {
            return {
                Authorization: this.$store.state.token,
            }
        },
        userSelected() {
            return this.$store.getters.userSelected
        },
    },
    watch: {
        // userSelected: function (newQuestion, oldQuestion) {
        //     this.getInstitusiPendidikan()
        //     this.getProdi()
        // }
    },
    created() {
        this.getInstitusiPendidikan()
        this.getProdi()
    },
    methods: {
        getInstitusiPendidikan() {
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getInstitusiPendidikans`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.institusi_pendidikans = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getProdi() {
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getProdis`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.prodis = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        simpan() {
            const headers = this.headers
            const fmData = new FormData()
            fmData.append('username', this.userTambahan.username)
            fmData.append('email', this.userTambahan.email)
            fmData.append('nama', this.userTambahan.nama)
            fmData.append('nim', this.userTambahan.nim)
            fmData.append('institusi_pendidikan', this.userTambahan.institusi_pendidikan)
            fmData.append('prodi', this.userTambahan.prodi)
            fmData.append('level', this.userTambahan.level)
            const dokumen = [];
            this.userTambahan.dokumen.forEach(element => {
                dokumen.push(element.id)
            });
            fmData.append('dokumen', dokumen)
            fmData.append('role_temp', this.userSelected.role_temp)
            axios
                .post(`${apiRoot}/api/RegisterLanjutan/addRegistrasiPesertaPpds`, fmData, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.$store.dispatch('logout', response.data.message)
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>